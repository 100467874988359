import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useNavigate } from "react-router-dom";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { Button } from "@/components/ui/button";

const stepOneSchema = z.object({
  firstName: z.string().min(1, ""),
  lastName: z.string().min(1, ""),
  rsvp: z.enum(["yes", "no"], { required_error: "Please select an RSVP option" }),
});

const stepTwoSchema = stepOneSchema.extend({
  numberOfAdults: z.number().int().min(0, ""),
  numberOfChildren: z.number().int().min(0, ""),
  dietaryRestrictions: z.string().optional(),
  emails: z.array(z.object({ email: z.string().email("Invalid email") })).min(1, ""),
});

const fullFormSchema = stepTwoSchema;

type FormData = z.infer<typeof fullFormSchema>;

export function MultiStepForm() {
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionResult, setSubmissionResult] = useState<string | null>(null);

  const formMethods = useForm<FormData>({
    resolver: zodResolver(currentStep === 0 ? stepOneSchema : stepTwoSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      rsvp: "yes",
      numberOfAdults: 0,
      numberOfChildren: 0,
      dietaryRestrictions: "",
      emails: [{ email: "" }],
    },
    mode: 'onSubmit', // Validate only on form submission
    reValidateMode: 'onSubmit', // Re-validate only on submission
  });

  const navigate = useNavigate();

  const handleSubmission = async (data: FormData, path: string) => {
    setIsSubmitting(true);
    setSubmissionResult(null);

    try {
      const response = await fetch("https://occarters-rsvp.azurewebsites.net/api/RSVP", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setSubmissionResult(await response.text());
        navigate(path);
      } else {
        const error = await response.text();
        setSubmissionResult(`Error: ${error}`);
      }
    } catch (error) {
      console.error("Submission error:", error);
      setSubmissionResult("An unexpected error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
    navigate(path);
  };

  const onSubmit = async (data: FormData) => {
    if (currentStep === 0) {
      if (data.rsvp === "no") {
        // Submit and go to "sorry" page if RSVP is "no"
        await handleSubmission(data, "/sorry");
      } else {
        // Move to Step 2 if RSVP is "yes"
        setCurrentStep(1);
      }
    } else if (currentStep === 1) {
      // Submit and go to "thank-you" page on final confirmation in Step 2
      await handleSubmission(data, "/thank-you");
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className="space-y-4 " autoFocus={false} >
        
        {currentStep === 0 && <StepOne />}
        {currentStep === 1 && <StepTwo />}

        <div className="flex justify-between items-center mt-4">
          {currentStep === 1 && (
            <Button type="button" onClick={() => setCurrentStep(0)} className="self-start bg-gray-600 text-white font-medium py-2 px-4 rounded-lg hover:bg-gray-700 transition-colors duration-200">
              Back
            </Button>
          )}
          <Button type="submit" className="mx-auto bg-blue-600 text-white font-medium pt-2 pb-1.5 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-200" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : currentStep === 1 ? "Confirm RSVP" : "NEXT"}
          </Button>
        </div>

        {submissionResult && <p className="text-center mt-4">{submissionResult}</p>}
      </form>
    </FormProvider>
  );
} 