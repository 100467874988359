import { MultiStepForm } from '@/components/MultiStepForm'; // Adjust the import path as necessary

function Home() {
  return (
    <div className="relative min-h-screen">
      {/* Background Video */}
      <video
        className="absolute inset-0 w-full h-full object-cover"
        src="https://ocmedia-adezbcaabacpchh3.z01.azurefd.net/squaremp4med.mp4"
        autoPlay
        loop
        muted
      />

      {/* Background Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-30"></div>

      {/* Main Container */}
      <div className="relative z-10 flex flex-col items-center justify-center min-h-screen w-full px-8">
        {/* Form Container */}
        <div className="w-full max-w-md bg-white/50 backdrop-blur-lg px-4 py-8 rounded-xl shadow-2xl">
          <MultiStepForm />
        </div>
      </div>
    </div>
  );
}

export default Home;




//'https://ocmedia-adezbcaabacpchh3.z01.azurefd.net/squaremp4med.mp4'